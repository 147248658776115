
// Body
$body-bg: #FBFCFF;

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;


//edit-color
$green-tag: #2ECC71;
$blue-dark: #092D74;
$blue-dark-80: #092d74b6;
$blue-text: #092D74;
$blue-light: #EEF5F6;
$blue-300: #5D8090;
$blue-500: #466c7e;
$blue-footer: #6E6F72;
$blue-footer-logo: #092D74;
$gray-text: #374A59;
$gray-300: #AFBBDB; 
$gray-500: #7684AA;
$gray-600: #7685AA;
$gray-700: #374A59;
$black-700: #081F32;

$theme-colors: (
  "primary":    $blue-dark,
  "info":       $blue-text,
  "secondary":  $gray-text,
  "success":    $green-tag,
);

@import '~bootstrap/scss/bootstrap';
@import '../master.scss';

.gray-500{
  color: $gray-500;
}
.blue-light{
  color: $blue-light;
}
.bg-blue-light{
  background-color: $blue-light;
}
.blue-300{
  color: $blue-300;
}
.bg-blue-300{
  background-color: $blue-300;
}
.blue-500{
  color: $blue-500;
}
.bg-blue-footer{
  background-color: $blue-footer;
}
.blue-footer-logo{
  color: $blue-footer-logo;
}
.bg-blue-footer-logo{
  background-color: $blue-footer-logo;
}
.bg-blue-dark-80{
  background: $blue-dark-80;
}
.bg-primary-30{
  background-color: #092d744d;
}
.bg-gray-500{
  background-color: $gray-500;
}
.gray-300{
  color: $gray-300;
}
.bg-gray-300{
  background-color: $gray-300;
}
.gray-500{
  color: $gray-500;
}
.gray-600{
  color: $gray-600;
}
.gray-700{
  color: $gray-700;
}
.black-700{
  color: $black-700;
}
.bg-black-700{
  background-color: $black-700;
}
.white{
  color: #fff;
}
.white-0-5{
  color: rgba(255, 255, 255, 0.5);
}
.white-0-8{
  color: rgba(255, 255, 255, 0.8);
}
.bg-gray-200-70{
  background-color: #f3f4f5b3;
}
.bg-message{
  background-color: #afbbdb4d;
}
.red{
  color: #D5293B;
}
.red-100{
  color: #FFD1E0;
}
.bg-red-100{
  background-color: #FFD1E0;
}
.red-200{
  color: #FF6188;
}

.home-info-card-bgRed{
  background: linear-gradient(319.36deg, #6E6F72 2.25%, #092D74 95.74%);
}


.mySwiperMain{
  .swiper-pagination-bullet{
    background-color: $gray-text;

    &-active{
      background: white;

    }
  }
}

.mySwiperVideos, .mySwiperChannelList{
  .swiper-pagination-bullet{
    background-color: #959DB3;

    &-active{
      background: $blue-text;

    }
  }
}

.mySwiperLogin{
  .swiper-pagination-bullet-active{
    background-color: $blue-300;
  }

}

.footer-bottom{
  background-color: $blue-footer;
}

.home-tools{
  width: 360px !important;
  transition: 0.3s;

  &:hover{
    transition: 0.3s;
    cursor: pointer;
    p, h4{
      transition: 0.3s;
      color:$blue-text;
      transition: 0.3s;
    }
    a{
      background-color: $blue-text;
      transition: 0.3s;
    }
    .icon-img{
      transition: 0.3s;
      path{   
        transition: 0.3s;
        stroke: $blue-text;
      }
      .path-fill{
        transition: 0.3s;
        fill: $blue-text;
      }

    }

  }
}

.calculate-card:hover .calculate-card-img{
  path{
    stroke: $blue-text !important;
  }
}

.coming-you-card:hover .coming-you-card-img{
  path{
    stroke: $blue-text !important;
  }
}

.category-card:hover .category-card-img{
  path{
    stroke: $blue-text !important;
  }
}